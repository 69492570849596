<template>
  <div
    :key="Object.keys($store.state.business.unsaved.include.weekdays).length"
  >
    <h4>
      {{ $tc("time.pause.title", Object.keys(pause).length) }} /
      {{ $t("time.title") }} {{ $t("time.weekdays.closed") }}
      <span v-if="Object.keys(pause).length"
        >({{ Object.keys(pause).length }})</span
      >
    </h4>

    <div :key="updateKey">
      <div v-for="(weekdays, time) in pause" :key="time" class="flix-mt-20">
        <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-10">
          <div class="flix-flex flix-flex-center flix-gap-10">
            <a href="#" class="flix-btn" @click.prevent="removePause(time)"
              ><icon id="menu-close"
            /></a>
            <timePickerRange
              :key="time"
              class="timePicker"
              :range="5"
              :time="time.split('-')"
              :callback="function (ret) { changeTime(time, ret) }"
            />
          </div>
          <ul
            class="flix-flex flex-flex-center flix-flex-inline flix-flex-list flix-gap-10"
            style="flex-direction: row"
          >
            <li
              v-for="(weekdayTimes, checkedWeekday) in $store.state.business
                .unsaved.include.weekdays"
              :key="checkedWeekday"
            >
              <a
                href="#"
                class="flix-html-a"
                @click.prevent="setWeekday(time, checkedWeekday)"
              >
                <icon
                  id="square-empty"
                  v-if="weekdays.indexOf(checkedWeekday + '') === -1"
                />
                <icon id="square-check" v-else />
                {{
                  $t("dates.weekdays")[checkedWeekday][0] +
                    $t("dates.weekdays")[checkedWeekday][1]
                }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <a href="#" class="flix-mt-20 flix-btn" @click.prevent="addPause"
      ><icon :id="'plus'" />
      {{ $t("time.pause.add", Object.keys(pause).length) }}</a
    >
  </div>
</template>
<script>
export default {
  components: {
    timePickerRange() {
      return import("@/components/default/form/timePickerRange");
    }
  },
  props: {},
  data() {
    var exclude = JSON.parse(JSON.stringify(this.$store.state.business.unsaved))
      .exclude.weekdays;
    return {
      exclude: exclude,
      pause: this.getPauseTimes(exclude),
      updateKey: 0
    };
  },
  methods: {
    setWeekday(key, weekday) {
      const index = this.pause[key].indexOf(weekday + "");
      if (index === -1) {
        this.pause[key].push(weekday + "");
      } else {
        this.pause[key].splice(index, 1);
      }
      this.setUpdateKey();
    },
    removePause(time) {
      delete this.pause[time];
      this.setUpdateKey();
    },
    getPauseLength() {
      return Object.keys(this.pause).length;
    },
    changeTime(time, newTime) {
      Object.defineProperty(
        this.pause,
        newTime.join("-"),
        Object.getOwnPropertyDescriptor(this.pause, time)
      );
      delete this.pause[time];
      this.setUpdateKey();
    },
    getPauseTimes(exclude) {
      var r = {};
      Object.keys(exclude).forEach(function(day) {
        if (exclude[day] === "null" || exclude[day] === null) {
          return true;
        }
        Object.values(exclude[day]).forEach(function(time) {
          if (typeof r[time.join("-")] === "undefined") {
            r[time.join("-")] = [];
          }
          if (r[time.join("-")].indexOf(day) === -1) {
            r[time.join("-")].push(day);
          }
        });
      });
      return r;
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.exclude.weekdays = this.exclude;
      this.$store.commit("business/prefetch", data);
    },
    setUpdateKey() {
      var exclude = {};
      this.updateKey = new Date().getTime();
      Object.keys(this.pause).forEach(
        function(time) {
          this.pause[time].forEach(
            function(weekday) {
              if (typeof exclude[weekday] === "undefined") {
                exclude[weekday] = [];
              }
              exclude[weekday].push(time.split("-"));
            }.bind(this)
          );
        }.bind(this)
      );

      this.exclude = exclude;
      this.setSave();
    },
    addPause() {
      var startHour = Math.floor(Math.random() * 22);
      var startMinute = Math.floor(Math.random() * 3);
      var timeRange = 15 + Math.floor(Math.random() * 2) * 15;
      var date = new Date();
      date.setHours(startHour);
      date.setMinutes(startMinute * 15);

      var endDate = new Date(date);
      endDate.setMinutes(endDate.getMinutes() + timeRange);

      this.pause[
        this.getNullsBefore(date.getHours()) +
          ":" +
          this.getNullsBefore(date.getMinutes()) +
          "-" +
          this.getNullsBefore(endDate.getHours()) +
          ":" +
          this.getNullsBefore(endDate.getMinutes())
      ] = [];
      this.setUpdateKey();
    },
    getNullsBefore(index) {
      if (index * 1 < 10) {
        return "0" + index;
      } else {
        return index + "";
      }
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.flix-html-a
  color: $dark-grey
</style>
